<template>
  <div>
    <h5 class="text-bold clickable" @click.self="expand = !expand">Farbthema
      <b-icon-caret-down-fill class="clickable" @click="expand = !expand" :flip-v="expand"/>
    </h5>
    <TransitionExpand>
      <b-form-group v-if="expand">
        <b-form-row class="mt-2">
          <b-col cols="3">
            <label>Farbthema:</label>
            <b-form-select v-model="themes.selected" :options="themes.options" size="sm"
                           @input="emitUserSetting"/>
          </b-col>
        </b-form-row>
      </b-form-group>
    </TransitionExpand>
  </div>
</template>

<script>
import TransitionExpand from "@/components/utility/TransitionExpand"
import {getRequest} from "@/modules/requests";

export default {
  name: "ThemeSettings",
  props: ['userSettings'],
  components: {
    TransitionExpand
  },
  data() {
    return {
      expand: false,
      themes: {
        selected: this.userSettings.frontend_theme_id,
        options: []
      }
    }
  },
  methods: {
    emitUserSetting() {
      this.userSettings.frontend_theme_id = this.themes.selected
      this.$emit('settings-changed', this.userSettings)
    },
    async getThemes() {
      this.themes.options = []
      await getRequest('userSettings/frontend-themes', null, this)
        .then((response) => {
          response.data.forEach(element => {
            this.themes.options.push({value: element.id, text: element.displayname})
          })
        })
    }
  },
  async mounted() {
    await this.getThemes()
  }
}
</script>

<style lang="scss" scoped>
</style>

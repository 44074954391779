<template>
  <span>
    <span v-if="computer.image">
      <span class="mr-2 badge-control clickable" title="Funktionen öffnen" :id="'po-image-' + computer.id" @click="onShowPoImage">
        {{ computer.image.displayname }}
      </span>
      <b-popover :target="'po-image-' + computer.id" triggers="manual" placement="left" :show.sync="popoverShowImage">
        <div>
          <span>Default Image: {{ imageSelect.default }}</span> <br>
          <span>Nächstes Image:</span>
          <b-form-select size="sm" class="mb-2" v-model="imageSelect.selected" :options="imageSelect.options"/>
          <b-button @click="onClosePoImage" size="sm" :variant="variantDanger" class="mr-1">Abbrechen</b-button>
          <b-button @click="onOkPoImage" size="sm" variant="primary">Ok</b-button>
        </div>
      </b-popover>
      <template v-if="computer.image.displayname !== computer.image_next.displayname">
        <b-tooltip :target="'tt-image-change' + computer.id" triggers="hover">
          <div class="text-left">
            Nächstes Image unterscheidet sich zu dem aktuellen Image
          </div>
        </b-tooltip>
        <b-icon-intersect :variant="variantWarning" :id="'tt-image-change' + computer.id" class="mr-2"/>
      </template>
      <b-icon-person-bounding-box v-if="computer.time_last_used" :id="'tt-last-used-' + computer.id" :variant="variantDanger"/>
    </span>
    <span v-else>Unbekannt</span>
    <b-tooltip v-if="computer.time_last_used" :target="'tt-last-used-' + computer.id" triggers="hover">
    <div class="text-left">
      Zuletzt verwendet am {{ computer.time_last_used | datetime }}<br>
      {{ computer.customer_last_used.zuname }}, {{ computer.customer_last_used.vorname }}
      ({{ computer.customer_last_used.kdnr }})<br>
      {{ computer.customer_last_used.kurs }}<br>
      Klassenraum-{{ computer.customer_last_used.intklr }}
    </div>
  </b-tooltip>
  </span>
</template>

<script>
import {getRequest, putRequest} from "@/modules/requests";
import {mapGetters} from "vuex";

export default {
  name: "ImageInformation",
  props: ['computer'],
  data() {
    return {
      popoverShowImage: false,
      imageSelect: {
        selected: null,
        options: null,
        default: ''
      }
    }
  },
  methods: {
    onClosePoImage() {
      this.popoverShowImage = false
    },
    async onShowPoImage() {
      await getRequest('/images', null, this)
          .then((response) => {
            this.imageSelect.options = []
            response.data.forEach(element => {
              this.imageSelect.options.push({value: element.id, text: element.displayname})
              if(element.default) {
                this.imageSelect.default = element.displayname
              }
              this.imageSelect.selected = this.computer.image_next.id
              this.popoverShowImage = true
            })
          })
    },
    async onOkPoImage() {
      let data = {
        image_next_id: this.imageSelect.selected,
      }
      await putRequest('/image-next/' + this.computer.pc_uuid, data, this, null)
          .then(() => {
            this.onClosePoImage()
            this.refresh()
          })
    },
    refresh() {
      this.$emit('refresh')
    },
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    },
    variantWarning() {
      if(this.userThemeId === 3) { return 'warning-deuteranopia' }
      return 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

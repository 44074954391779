<template>
  <div>
    <h5 class="text-bold clickable" @click.self="expand = !expand">VPN-Zertifikat
      <b-icon-caret-down-fill class="clickable" @click="expand = !expand" :flip-v="expand"/>
    </h5>
    <TransitionExpand>
      <div v-if="expand">
        <b-row>
          <b-col cols="4">
            <b-card :bg-variant="bgVariant">
              <b-row class="mb-1">
                <b-col v-if="userVPN" class="text-left text-bold">
                  {{ vpnName }}
                </b-col>
                <b-col v-else class="text-left text-bold">
                  Kein Zertifikat vorhanden
                </b-col>
              </b-row>
              <b-row v-if="userVPN">
                <b-col class="text-left">
                  Erstellt am:
                </b-col>
                <b-col class="text-right">
                  <span >{{ userVPN.issued_at | datetime }}</span>
                </b-col>
              </b-row>
              <b-row v-if="userVPN">
                <b-col class="text-left">
                  Läuft aus am:
                </b-col>
                <b-col class="text-right">
                  <span >{{ userVPN.expire_at | datetime }}</span>
                </b-col>
              </b-row>
              <b-row class="mt-2" v-if="userVPN">
                <b-col>
                  <b-button variant="secondary" size="sm" @click="revokeVPN">
                    <b-spinner v-show="revokeVPNButton" small/>
                    VPN-Zertifikat zurückziehen
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mt-2" v-if="userVPN">
                <b-col>
                  <b-button variant="secondary" size="sm" @click="downloadVPN">
                    <b-spinner v-show="downloadVPNButton" small/>
                    VPN-Zertifikat herunterladen
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-button variant="primary"  size="sm" @click="newVPN">
                    <b-spinner v-show="newVPNButton" small/>
                    Neues VPN-Zertifikat erstellen
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </TransitionExpand>
  </div>
</template>

<script>
import TransitionExpand from "@/components/utility/TransitionExpand"
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "UserVPN",
  props: ['userVPN'],
  components: {
    TransitionExpand
  },
  data() {
    return {
      expand: true,
      newVPNButton: false,
      revokeVPNButton: false,
      downloadVPNButton: false
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    async newVPN() {
      this.newVPNButton = true
      await axios.post(process.env.VUE_APP_API_USERS_VPN_NEW)
          .then(() => {
            this.newVPNButton = false
            this.refresh()
          })
    },
    async revokeVPN() {
      this.revokeVPNButton = true
      await axios.post(process.env.VUE_APP_API_USERS_VPN_REVOKE)
          .then(() => {
            this.revokeVPNButton = false
            this.refresh()
          })
    },
    async downloadVPN() {
      this.downloadVPNButton = true
      await axios.get(process.env.VUE_APP_API_USERS_VPN_DOWNLOAD)
          .then((response) => {
            this.downloadVPNButton = false
            const blob = new Blob([response.data], {type: response.headers["content-type"]})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = response.headers["content-disposition"].split('=')[1]
            link.click()
            URL.revokeObjectURL(link.href)
          })
    }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters(['userThemeId']),
    bgVariant() {
      if(this.userThemeId === 2) { return 'light' }
      else return 'grey-700'
    },
    vpnName() {
      return this.user.username + '.ovpn'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/UserProfil.scss';
</style>

<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="2"/>
        <b-col cols="8">
          <b-jumbotron class="jumbotron-background py-4 mt-3" header="Benutzereinstellungen" header-level="5">
            <hr class="line-white">
            <b-form v-if="userSettings" @submit.stop.prevent="">
              <MailSettings :userSettings="userSettings" @settings-changed="settingsChanged"/>
              <hr class="line-white">
              <SiteSettings/>
              <hr class="line-white">
              <FavoriteSiteSettings :userSettings="userSettings" :key="favoriteSettingComponentKey"
                                    @settings-changed="settingsChanged"/>
              <hr class="line-white">
              <ThemeSettings :userSettings="userSettings" @settings-changed="settingsChanged"/>
              <hr class="line-white">
              <ReplacementSettings :userSettings="userSettings" @settings-changed="settingsChanged"/>
              <hr class="line-white">
              <b-row>
                <b-col cols="4">
                  <b-button :variant="variantSuccess" class="mr-2" @click="updateUserSettings">
                    <b-spinner v-show="loadingUpdateSettings" small/>
                    Speichern
                  </b-button>
                  <b-button variant="outline-primary" @click="resetUserSettings">Standard wiederherstellen</b-button>
                </b-col>
                <b-col cols="auto" class="mt-2 ml-auto text-right">
                  <span class="text-secondary">Letzte Änderungen: {{ userSettings.updated_at | datetime }}</span>
                </b-col>
              </b-row>
            </b-form>
          </b-jumbotron>
        </b-col>
        <b-col cols="2"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import {getRequest, putRequest} from "@/modules/requests"
import ScrollToTop from "@/components/utility/ScrollToTop"
import MailSettings from "@/components/userAccount/userSettings/MailSettings"
import SiteSettings from "@/components/userAccount/userSettings/SiteSettings"
import ThemeSettings from "@/components/userAccount/userSettings/ThemeSettings"
import ReplacementSettings from "@/components/userAccount/userSettings/ReplacementSettings"
import FavoriteSiteSettings from "@/components/userAccount/userSettings/FavoriteSiteSettings"

export default {
  name: "UserSettings",
  components: {
    ReplacementSettings,
    ThemeSettings,
    SiteSettings,
    ScrollToTop,
    MailSettings,
    FavoriteSiteSettings
  },
  data() {
    return {
      userSettings: null,
      loadingUpdateSettings: false,
      favoriteSettingComponentKey: 0
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    ...mapActions(['setUserSettings']),
    ...mapActions(['setUserThemeId']),
    settingsChanged(data) {
      this.userSettings = data
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getUserSettings()
      await window.scrollTo(0, 0)
      this.setLoadingState(false)
    },
    async getUserSettings() {
      await getRequest('userSettings/all', null, this)
          .then((response) => {
            this.userSettings = response.data
            this.setUserSettings(this.userSettings)
            this.setUserThemeId(this.userSettings.frontend_theme_id)
            this.favoriteSettingComponentKey += 1
          })
    },
    async updateUserSettings() {
      let data = {
        notification_email_news: this.userSettings.notification_email_news,
        notification_email_license_notice: this.userSettings.notification_email_license_notice,
        notification_email_command_inactive: this.userSettings.notification_email_command_inactive,
        frontend_theme_id: this.userSettings.frontend_theme_id,
        replacement_users: this.userSettings.replacement_users,
        site_favorites: this.userSettings.site_favorites
      }
      this.loadingUpdateSettings = true
      await putRequest('userSettings/update', data, this, null)
          .then(() => {
            this.loadingUpdateSettings = false
            this.refresh()
          })
    },
    async resetUserSettings() {
      await putRequest('userSettings/reset', null, this, null)
          .then(() => {
            this.refresh()
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
  },
  created() {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Dashboard';

.line-white {
  border-color: white;
}
</style>

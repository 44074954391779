<template>
  <div>
    <h5 class="text-bold clickable" @click.self="expand = !expand">Standorteinstellung
      <b-icon-caret-down-fill class="clickable" @click="expand = !expand" :flip-v="expand"/>
    </h5>
    <TransitionExpand>
      <b-form-row v-if="expand">
        <b-col cols="3">
          <b-form-select v-model="newSiteSelected" :options="siteList" size="sm"/>
        </b-col>
        <b-col>
          <b-button :variant="variantSuccess" size="sm" id="tt-info-site" @click="updateSite">Speichern</b-button>
          <b-icon-info-circle-fill class="ml-2" id="tt-info-site1"/>
          <b-tooltip target="tt-info-site" triggers="hover">Damit diese Änderung einen Effekt hat, muss sich neu angemeldet werden</b-tooltip>
          <b-tooltip target="tt-info-site1" triggers="hover">Die Teamzuordnung wird ebenfalls geändert</b-tooltip>
        </b-col>
      </b-form-row>
    </TransitionExpand>
  </div>
</template>

<script>
import _ from "lodash"
import {mapGetters} from "vuex"
import {putRequest} from "@/modules/requests"
import TransitionExpand from "@/components/utility/TransitionExpand"

export default {
  name: "SiteSettings",
  props: ['userSettings'],
  components: {
    TransitionExpand
  },
  data() {
    return {
      expand: false,
      newSiteSelected: null
    }
  },
  methods: {
    async updateSite() {
      let data = {site: this.newSiteSelected}
      await putRequest('userSettings/update-site', data, this, 'Neuer Standort erfolgreich eingestellt.', 'Neuer Standort konnte nicht eingestellt werden.')
    }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters(['sites']),
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 2) { return 'success-deuteranopia' }
      return 'success'
    },
    siteList() {
      let siteList = []
      _.forEach(this.sites, (site) => {
        siteList.push({value: site.shortname, text: site.displayname})
      })
      return siteList
    }
  },
  mounted() {
    this.newSiteSelected = this.user.site.shortname
  }
}
</script>

<style lang="scss" scoped>
</style>

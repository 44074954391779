<template>
  <span>
    <span v-if="!hostnameControl" @click="hostnameControl = true" class="badge-control clickable"
          title="Funktionen öffnen">
      {{ computer.hostname }}
    </span>
    <span v-else style="margin-top: -2px">
      <ClipboardUtility :value="computer.hostname" title="Hostname kopieren"/>
      <CustomerAssign :computer="computer" @refresh="refresh"/>
      <b-badge variant="primary" class="p-1 px-2 b-m clickable" title="Remoteverbindung öffnen">
        <b-icon-display scale="1.4" @click="remoteAccess"/>
      </b-badge>
      <b-badge v-if="computer.online" variant="primary" class="p-1 px-2 b-m clickable" title="Computer herunterfahren">
        <b-icon-power scale="1.4" @click="shutdown"/>
      </b-badge>
      <b-badge v-else variant="primary" class="p-1 px-2 b-m clickable" title="WOL ausführen">
        <b-icon-power scale="1.4" @click="wol"/>
      </b-badge>
      <b-badge variant="primary" class="p-1 px-2 b-m clickable" title="PC löschen">
        <b-icon-trash-fill scale="1.4" @click="onDeleteModalShow(computer.pc_uuid)"/>
      </b-badge>
      <b-badge :variant="variantDanger" @click="hostnameControl = false" class="p-1 px-2 b-r clickable" title="Schließen">
        <b-icon-x scale="1.8"/>
      </b-badge>
    </span>
    <DeleteModal :pcUuid="computer.pc_uuid" :pcHostname=computer.hostname @refresh="refresh"
                 @close-details="closeDetails"/>
    <b-icon-info-circle v-if="computer.details.computer.note && !hostnameControl" scale="0.8"
                        :id="'tt-icon-note-' + computer.pc_uuid"/>
    <b-tooltip :target="'tt-icon-note-' + computer.pc_uuid">Es ist eine Notiz vorhanden</b-tooltip>
  </span>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {putRequest} from "@/modules/requests";
import {toastDanger, toastSuccess} from "@/modules/status";
import DeleteModal from "@/components/lists/computerList/DeleteModal.vue";
import ClipboardUtility from "@/components/utility/ClipboardUtility";
import CustomerAssign from "@/components/utility/CustomerAssign.vue";

export default {
  name: "HostnameControl",
  props: ['computer'],
  components: {
    DeleteModal,
    CustomerAssign,
    ClipboardUtility
  },
  data() {
    return {
      hostnameControl: false,
      popoverShowCustomerAssigned: false,
      popoverInput: '',
      popoverInputState: null,
      customerId: null,
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    closeDetails() {
      this.$emit('close-details')
    },
    wol() {
      putRequest('wol/' + this.computer.pc_uuid, null, this, 'WOL erfolgreich versendet.')
    },
    shutdown() {
      putRequest('shutdown/' + this.computer.pc_uuid, null, this, 'Shutdown erfolgreich versendet.')
    },
    remoteAccess() {
      let config = {
        method: 'get',
        url: 'rc/' + this.computer.pc_uuid
      }

      axios(config)
          .then((response) => {
            toastSuccess(this, 'Remoteverbindung wird in einem neuen Tab geöffnet.')
            window.open(response.data.url, '_blank')
          })
          .catch(() => {
            toastDanger(this, 'Remoteverbindung konnte nicht aufgebaut werden!');
          })
    },
    onDeleteModalShow(id) {
      this.$bvModal.show('deleteModal-' + id)
    }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters(['userThemeId']),
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    },
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <b-row @click.self="getDetails" class="task-row">
    <b-col cols="auto" v-for="task in computer.tasks" :key="task.id" class="pl-0" @click="onEditModalOpen(computer.pc_uuid, task)">
      <b-progress v-if="task.status === 'error'" striped :variant="variantDanger" :max="1" :id="'tt-task-error-' + task.id"
                  class="task mb-1 clickable">
        <b-progress-bar :value="1" class="px-1 task-error text-white text-border">
        <span v-if="task.type === 'hostname'" class="text-left progress-bar-fix" :title="task.displayname">
          {{ task.displayname }} - {{ task.command_parameter }}
        </span>
        <span v-else class="text-left progress-bar-fix" :title="task.displayname">
          {{ task.displayname }}
        </span>
        </b-progress-bar>
        <b-tooltip :target="'tt-task-error-' + task.id" triggers="hover">
          Geendet am {{ task.time_execute_end | datetime }}<br>
          gesetzt von {{ task.issuer }}
        </b-tooltip>
      </b-progress>
      <b-progress v-if="task.status === 'pending'" :max="1" striped :id="'tt-task-pending-' + task.id"
                class="task mb-1 clickable">
      <b-progress-bar :value="1" class="text-white text-border px-1 task-pending">
        <span v-if="task.type === 'hostname'" class="text-left progress-bar-fix" :title="task.displayname">
          {{ task.displayname }} - {{ task.command_parameter }}
        </span>
        <span v-else class="text-left progress-bar-fix" :title="task.displayname">
          {{ task.displayname }}
        </span>
        <span class="text-right">
          <b-icon-eject-fill v-if="task.type === 'install'" :variant="variantSuccess" flip-v/>
          <b-icon-eject-fill v-else-if="task.type === 'deinstall'" :variant="variantDanger"/>
          <b-icon-cloud-arrow-down-fill v-else-if="task.type === 'sync'" scale="1.3" :variant="variantWarning"/>
          <b-icon-stopwatch-fill class="ml-1"/>
        </span>
      </b-progress-bar>
      <b-tooltip :target="'tt-task-pending-' + task.id" triggers="hover">
        Geplant am {{ task.time_execute_scheduled | datetime }}<br>
        gesetzt von {{ task.issuer }}
      </b-tooltip>
    </b-progress>
      <b-progress v-if="task.status === 'running'" :max="task.progress_max" animated :id="'tt-task-running-' + task.id"
                  class="task task-running clickable">
      <b-progress-bar :value="task.progress_current" class="text-white text-border px-1">
        <span v-if="task.type === 'hostname'" class="text-left progress-bar-fix" :title="task.displayname">
        {{ task.displayname }} - {{ task.command_parameter }}
      </span>
        <span v-else class="text-left progress-bar-fix" :title="task.displayname">
        {{ task.displayname }}
      </span>
        <span class="text-right">
          <b-icon-eject-fill v-if="task.type === 'install'" :variant="variantSuccess" flip-v/>
          <b-icon-eject-fill v-else-if="task.type === 'deinstall'" :variant="variantDanger"/>
          <b-icon-cloud-arrow-down-fill v-else-if="task.type === 'sync'" scale="1.3" :variant="variantWarning"/>
        </span>
      </b-progress-bar>
      <b-tooltip :target="'tt-task-running-' + task.id" triggers="hover">
        Gestartet am {{ task.time_execute_start | datetime }}
        gesetzt von {{ task.issuer }}
      </b-tooltip>
    </b-progress>
    </b-col>

    <b-icon-plus-circle-fill variant="primary" class="computer-icon clickable" :id="'tt-task-add-' + computer.id"
                             @click="onCreateModalShow(computer.pc_uuid)"/>
    <b-tooltip :target="'tt-task-add-' + computer.id" triggers="hover">Neue Aktion hinzufügen</b-tooltip>

    <b-icon-mailbox2 v-if="readyToShipCheck" variant="primary" class="computer-icon ml-2"
                     :id="'tt-icon-ready-shipping-' + computer.id" @click="onReadyToShipModalShow(computer.pc_uuid)"/>
    <b-tooltip v-if="readyToShipCheck" :target="'tt-icon-ready-shipping-' + computer.id"
               triggers="hover">Rechner versandbereit markieren</b-tooltip>

    <b-icon-box-seam v-if="computer.details.software.licenses" class="computer-icon ml-2"
                     :id="'tt-icon-packets-installed-' + computer.id"/>
    <b-tooltip v-if="computer.details.software.licenses" :target="'tt-icon-packets-installed-' + computer.id"
               custom-class="tooltip-width-100" triggers="hover">
      <div v-for="disk in computer.details.software.licenses" :key="disk.id">
        <div v-for="license in disk.licenses" :key="license.id">
          {{ license.license.displayname }}
        </div>
      </div>
    </b-tooltip>

    <b-icon-cloud-download v-if="computer.details.software.syncs" class="computer-icon ml-2"
                           :id="'tt-icon-packets-synced-' + computer.id"/>
    <b-tooltip v-if="computer.details.software.syncs" :target="'tt-icon-packets-synced-' + computer.id"
               custom-class="tooltip-width-100" triggers="hover">
      <div v-for="disk in computer.details.software.syncs" :key="disk.id">
        <div v-for="sync in disk.syncs" :key="sync.id">
          <div>{{ sync.sync.displayname }} | ({{sync.progress}}%)</div>
        </div>
      </div>
    </b-tooltip>

    <CreateTaskModal :pcUuid="computer.pc_uuid" :pcId=computer.id :imageAktuell=computer.image.displayname
                     :groupList="false" :imageNext=computer.image_next.displayname :pcHostname=computer.hostname
                     :packetsInstalled="computer.details.software.licenses" @refresh="refresh"/>
    <EditTaskModal :task=editTask :pcUuid="computer.pc_uuid" :pcHostname=computer.hostname @refresh="refresh"/>
    <ReadyToShipModal :pcUuid="computer.pc_uuid" :pcId="computer.id" :pcCustomerAssigned="computer.customer_assigned"
                      :pcHostname="computer.hostname"/>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";
import EditTaskModal from "@/components/lists/computerList/tasks/editTaskModal/EditTaskModal";
import CreateTaskModal from "@/components/lists/computerList/tasks/createTaskModal/CreateTaskModal";
import ReadyToShipModal from "@/components/lists/computerList/ReadyToShipModal.vue";

export default {
  name: "TasksDisplay",
  props: ['computer'],
  components: {
    ReadyToShipModal,
    EditTaskModal,
    CreateTaskModal
  },
  data() {
    return {
      editTask: null,
    }
  },
  methods: {
    onEditModalOpen(id, task) {
      if(task.status === 'pending') {
        this.editTask = task;
        this.$bvModal.show('editTaskModal-' + id)
      }
      else if(task.status === 'running' && task.type === 'sync') {
        this.editTask = task;
        this.$bvModal.show('editTaskModal-' + id)
      }
    },
    onCreateModalShow(id) {
      this.$bvModal.show('createTaskModal-' + id)
    },
    onReadyToShipModalShow(id) {
      this.$bvModal.show('readyToShipModal-' + id)
    },
    getDetails() {
      this.$emit('get-details');
    },
    refresh() {
      this.$emit('refresh')
    },
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    },
    variantWarning() {
      if(this.userThemeId === 3) { return 'warning-deuteranopia' }
      return 'warning'
    },
    readyToShipCheck() {
      let reg = /HOME-L-[\\0-9]{3}/
      return this.computer.hostname.match(reg) != null;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/Tasks.scss';
</style>
